import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Label, Form, Button, Alert, FormGroup } from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Client from 'src/client'
import { useGetActionsInfoQuery } from 'src/services/communications'
import { DangerAlert } from '../ErrorPage'
import LoadingPage from '../LoadingPage'

export const InputItem = ({
  strategy_id,
  updateComp,
  setUpdateComp,
  hours
}) => {
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState('-')
  const [templates, setTemplates] = useState([])
  const [successful, setSuccessful] = useState(false)
  const [paramError, setParamError] = useState(null)

  const { data: actionsInfo, isLoading, error } = useGetActionsInfoQuery()

  const selectedAction = actionsInfo?.find(
    (action) => action.name === selectedType
  )
  const selectedActionFields = selectedAction?.fields || {}

  const phoneTypeOptions = Array.isArray(selectedActionFields.phone_type)
    ? selectedActionFields.phone_type.map((type) => {
        return {
          value: type,
          label: t(type)
        }
      })
    : []

  const initialValues = Object.keys(selectedActionFields).reduce((acc, key) => {
    acc[key] = ''
    return acc
  }, {})

  useEffect(() => {
    if (selectedType !== '-' && 'template_id' in selectedActionFields) {
      Client.getTemplates(selectedType).then(setTemplates).catch(setParamError)
    } else {
      setTemplates([])
    }
  }, [selectedType, selectedAction])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      Client.addActionStrategy(strategy_id, selectedType, values)
        .then(() => {
          setSuccessful(true)
          setUpdateComp(!updateComp)
        })
        .catch((err) => {
          setSuccessful(false)
          setParamError(err)
        })
    }
  })

  if (isLoading) return <LoadingPage />
  if (error) return <DangerAlert error={error} />

  return (
    <Form className='needs-validation mt-4' onSubmit={formik.handleSubmit}>
      <Label htmlFor='actionType'>{t('type_of_activity')}</Label>
      <Input
        id='actionType'
        type='select'
        className='form-control form-control-sm mb-2'
        value={selectedType}
        onChange={(e) => {
          setSelectedType(e.target.value)
          setSuccessful(false)
          setParamError(null)
        }}
      >
        <option value='-'>-</option>
        {actionsInfo?.map((action) => (
          <option key={action.name} value={action.name}>
            {t(action.name)}
          </option>
        ))}
      </Input>

      {selectedType !== '-' &&
        Object.entries(selectedActionFields).map(([fieldName, fieldType]) => {
          if (fieldName === 'template_id') {
            return (
              <FormGroup key={fieldName}>
                <Label htmlFor={fieldName}>{t('message_template_id')}</Label>
                <Input
                  id={fieldName}
                  type='select'
                  className='form-control form-control-sm'
                  name={fieldName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[fieldName] ?? ''}
                  disabled={templates.length === 0}
                >
                  <option value=''>{t('-')}</option>
                  {templates.map((tpl) => (
                    <option key={tpl.id} value={tpl.id}>
                      {tpl.template}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )
          }

          if (fieldType === 'time') {
            return (
              <FormGroup key={fieldName}>
                <Label htmlFor={fieldName}>
                  {fieldName === 'start_time'
                    ? t('Время начала отправки')
                    : fieldName === 'end_time'
                    ? t('Время окончания отправки')
                    : t(fieldName)}
                </Label>
                <Input
                  id={fieldName}
                  type='select'
                  className='form-control form-control-sm'
                  name={fieldName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[fieldName] ?? ''}
                >
                  <option value=''>{t('--')}</option>
                  {hours.map((hour) => (
                    <option key={hour} value={`${hour}:00`}>
                      {`${hour}:00`}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )
          }

          if (Array.isArray(fieldType)) {
            return (
              <FormGroup key={fieldName}>
                <Label htmlFor={fieldName}>{t('Тип номера')}</Label>
                <Input
                  id={fieldName}
                  type='select'
                  className='form-control form-control-sm'
                  name={fieldName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[fieldName] ?? ''}
                >
                  <option value=''>{t('-')}</option>
                  {phoneTypeOptions.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )
          }

          if (fieldType === 'number') {
            return (
              <FormGroup key={fieldName}>
                <Label htmlFor={fieldName}>
                  {fieldName === 'dpd_start'
                    ? t('День начала')
                    : fieldName === 'dpd_end'
                    ? t('День окончания')
                    : t(fieldName)}
                </Label>
                <Input
                  id={fieldName}
                  type='number'
                  className='form-control form-control-sm'
                  name={fieldName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[fieldName] ?? ''}
                />
              </FormGroup>
            )
          }

          return (
            <FormGroup key={fieldName}>
              <Label htmlFor={fieldName}>{t(fieldName)}</Label>
              <Input
                id={fieldName}
                type='text'
                className='form-control form-control-sm'
                name={fieldName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[fieldName] ?? ''}
              />
            </FormGroup>
          )
        })}

      {!!paramError && <Alert color='warning'>{paramError.toString()}</Alert>}
      {successful && <Alert color='success'>{t('Operation success')}</Alert>}

      <Button color='primary' type='submit' disabled={selectedType === '-'}>
        {t('Create')}
      </Button>
    </Form>
  )
}
