import { useEffect, useRef, useState } from 'react'
import { Web } from 'sip.js'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import Connected from './Connected'
import sounds from '../sounds.json'
import { SimpleUserDelegate } from 'sip.js/lib/platform/web'
import InProgress from './InProgress'
import OutComming from './OutComming'
import { useDispatch, useSelector } from 'src/store'
import { useSubscribeToCallsQuery } from 'src/services/calling'
import { useNavigate } from 'react-router-dom'
import { setLastMessage } from 'src/store/calling/calling-slice'

interface WebPhoneUI {
  numberFromLoan?: string
  isOpen: boolean
  open: () => void
  onToggle: () => void
  close: () => void
}

let simpleUser: Web.SimpleUser
export default function WebPhoneUI({
  numberFromLoan = '',
  isOpen = false,
  onToggle,
  open,
  close
}: WebPhoneUI) {
  const audioRingtone = new Audio()
  const [socketUrl, setSocketUrl] = useState<string>('')
  const [login, setLogin] = useState<string>('')
  const [number, setNumber] = useState<string>(
    numberFromLoan ? numberFromLoan : ''
  )
  const [password, setPassword] = useState<string>('')
  const [connected, setConnected] = useState<boolean>(false)
  const [isOutCommingCall, setIsOutCommingCall] = useState<boolean>(false)
  const [sessionInProgress, setSessionInProgress] = useState<boolean>(false)
  const [calling, setCalling] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(
    simpleUser ? simpleUser.isConnected() : false
  )
  const navigate = useNavigate()
  const remoteAudioRef = useRef<HTMLAudioElement | null>(null)

  const isReadyToCall = useSelector((state) => state.calling.isReadyToCall)

  const [id, setId] = useState<string>('')

  const user = useSelector((state) => state.Login.userInfo)

  const dispatch = useDispatch()

  const { data } = useSubscribeToCallsQuery(undefined, {
    skip: !isReadyToCall
  })

  // WebSocket server to connect with
  // const server = 'wss://rtp.dev.cash-u.com/ws'
  const server = socketUrl
  const delegate: SimpleUserDelegate = {
    onCallHangup: async () => {
      close()
      setIsOutCommingCall(false)
      setCalling(false)
      setSessionInProgress(false)
      audioRingtone.pause()
    },
    onCallAnswered: async () => {
      audioRingtone.pause()
    },
    onCallReceived: async () => {
      audioRingtone.src = sounds.ringing
      audioRingtone.loop = true
      audioRingtone.play()
      setTimeout(async () => {
        await simpleUser.answer()
        open()
        setSessionInProgress(true)
        setId(simpleUser.id)
      }, 2000)
    }
  }

  useEffect(() => {
    if (data) {
      if (data.messages && data.messages.length > 0) {
        const lastMessage = data.messages[data.messages.length - 1]
        dispatch(setLastMessage(lastMessage))
        if (lastMessage.loan_id) {
          navigate(`/automated-calling?id=${lastMessage.loan_id}`)
        }
        if (lastMessage?.action === 'result_timeout') {
          navigate(`/loans`)
        }
      }
      if (data.login && data.password && user?.asterisk.url && isReadyToCall) {
        const newSocketUrl = user?.asterisk.url
        setLogin(data.login)
        setPassword(data.password)
        setSocketUrl(newSocketUrl)
      }
    }
  }, [data, user?.asterisk.url, navigate, dispatch, isReadyToCall])

  useEffect(() => {
    if (login && password && socketUrl && isReadyToCall) {
      authInAsterisk(login, password, socketUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, password, socketUrl, isReadyToCall])

  //TODO: need testing && fix
  useEffect(() => {
    if (!isReadyToCall) {
      setLogin('')
      setPassword('')
      setSocketUrl('')
      if (simpleUser) {
        simpleUser.disconnect()
        simpleUser.unregister()
        setConnected(false)
        setStatus(false)
      }
    }
  }, [isReadyToCall])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authInAsterisk = async (
    login: string,
    password: string,
    server: string
  ) => {
    function getAudioElement(): HTMLAudioElement {
      if (remoteAudioRef.current) {
        return remoteAudioRef.current
      }
      throw new Error('remoteAudio ref not found.')
    }

    const options: Web.SimpleUserOptions = {
      aor: `sip:${login}@${server.split('://')[1].toString().split('/')[0]}`, // caller
      delegate: delegate,
      userAgentOptions: {
        authorizationPassword: `${password}`,
        authorizationUsername: `${login}`,
        transportOptions: {
          wsServers: [server],
          keepAliveInterval: 30
        }
      },
      media: {
        constraints: { audio: true, video: false }, // audio only call
        remote: { audio: getAudioElement() } // play remote audio
      }
    }
    console.log(options, password, login, '!!!')
    simpleUser = new Web.SimpleUser(server, options)
    await simpleUser.connect()
    await simpleUser.register().then(() => {
      setConnected(simpleUser.isConnected())
      setStatus(simpleUser.isConnected())
    })
  }

  const call = () => {
    audioRingtone.src = sounds.ringback
    if (simpleUser) {
      simpleUser
        .call(
          `sip:${number}@${server.split('://')[1].toString().split('/')[0]}`
        )
        .then(() => audioRingtone.play())
        .then(() => setSessionInProgress(true))
    } else {
      authInAsterisk(login, password, server).then(() => {
        simpleUser
          .call(
            `sip:${number}@${server.split('://')[1].toString().split('/')[0]}`
          )
          .then(() => audioRingtone.play())
          .then(() => setSessionInProgress(true))
      })
    }
  }

  const decline = () => {
    const session = (simpleUser as any).session
    if (session && session.state === 'Initial') {
      session
        .reject({
          statusCode: 603,
          reasonPhrase: 'Decline'
        })
        .then(() => setSessionInProgress(false))
    } else {
      simpleUser.hangup().then(() => setSessionInProgress(false))
    }
  }

  const answer = async () => {
    await simpleUser.answer().then(() => setCalling(true))
  }

  const holdMic = () => {
    simpleUser.hold()
  }

  const unholdMic = () => {
    simpleUser.unhold()
  }

  const toggle = () => {
    audioRingtone.pause()
    onToggle()
  }

  useEffect(() => {
    setNumber(numberFromLoan)
  }, [numberFromLoan])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    simpleUser && setStatus(true)
    if (!simpleUser) {
      setStatus(false)
      setConnected(false)
    }
  }, [])

  return (
    <>
      <audio
        ref={remoteAudioRef}
        id='remoteAudio'
        style={{ display: 'none' }}
      ></audio>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <div className='d-flex gap-3'>
            <div>WebPhone</div>
            <div>
              Статус: {status ? 'Соединение установлено' : 'Соединения нет'}
            </div>
          </div>
        </ModalHeader>
        <ModalBody id='sipPhoneModal'>
          {/*{!connected && (*/}
          {/*  <>*/}
          {/*    <div>Логин</div>*/}
          {/*    <Input*/}
          {/*      type="text"*/}
          {/*      placeholder="Введите Ваш логин"*/}
          {/*      value={login}*/}
          {/*      onChange={(e) => {*/}
          {/*        setLogin(e.target.value)*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <br />*/}
          {/*    <div>Пароль</div>*/}
          {/*    <Input*/}
          {/*      type="password"*/}
          {/*      placeholder="Введите Ваш пароль"*/}
          {/*      value={password}*/}
          {/*      onChange={(e) => {*/}
          {/*        setPassword(e.target.value)*/}
          {/*      }}*/}
          {/*    />*/}
          {/*    <br />*/}
          {/*    <Button type="button" onClick={authInAsterisk}>*/}
          {/*      Авторизоваться в системе*/}
          {/*    </Button>*/}
          {/*    <br />*/}
          {/*  </>*/}
          {/*)}*/}
          {connected && (
            <>
              {isOutCommingCall ? (
                <OutComming
                  id={id}
                  call={calling}
                  reject={decline}
                  answer={answer}
                  hold={holdMic}
                  unHold={unholdMic}
                />
              ) : (
                <>
                  {sessionInProgress ? (
                    <InProgress
                      reject={decline}
                      number={number}
                      hold={holdMic}
                      unHold={unholdMic}
                    />
                  ) : (
                    <Connected
                      reject={decline}
                      call={call}
                      value={number}
                      onChange={setNumber}
                    />
                  )}
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
