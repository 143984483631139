import List from './List'
import { useState } from 'react'
import MetaBar from '../MetaBar'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Col,
  Row,
  Label,
  FormFeedback,
  Alert
} from 'reactstrap'
import { useGetLoanPaymentsQuery } from 'src/services/loans'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import { useSetChargebackMutation } from 'src/services/payments'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'

const fieldsDescription = {
  id: { isShortId: false, isLink: true, linkFieldName: 'id_link' },
  operation_date: { isDate: true },
  created_at: { isDateTime: true },
  direction: {},
  amount: { isAmount: true, rightAligned: true },
  payment_gate: { fieldName: 'comment' },
  /*is_accepted: {
    mappingValue: (value) => value ? 'Confirmed' : 'Waiting',
    mappingClassName: (value) => value ? 'text-success' : 'text-warning'
  },*/
  accept_date: { isDateTime: true }
}

// TODO: use rtkq only, remove `entity` and `error` props
const EntityContent = ({ style, entityId, entity, visible, error }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [transactionId, setTransactionId] = useState('')

  const { data: payments, error: rtkqError } = useGetLoanPaymentsQuery(
    entityId,
    {
      skip: !visible || entityId == null
    }
  )
  const [
    setChargeback,
    { isSuccess, isError, isLoading, error: setChargebackError }
  ] = useSetChargebackMutation()

  const toggle = () => setShowModal(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      transaction_id: transactionId,
      chargeback_date: ''
    },
    validationSchema: Yup.object().shape({
      chargeback_date: Yup.string().required(t('No date specified'))
    }),
    onSubmit: (values) => {
      setChargeback(values)
    }
  })

  return (
    <div>
      {payments?.length ? (
        <>
          <List
            list={payments.map((item) => ({
              ...item,
              id_link: `/payment-view?id=${item.id}`,
              profile_link: `/profile-view?id=${item.profile_id}`,
              loan_link: `/loan-view?id=${item.loan_id}`
            }))}
            fieldsDescription={fieldsDescription}
            style={style}
            actions={payments.map((item, rank) => {
              if (item.is_accepted && item.direction === 'In') {
                return (
                  <Button
                    key={`action-${rank}`}
                    size='sm'
                    onClick={() => {
                      setShowModal(true)
                      setTransactionId(item.id)
                    }}
                  >
                    {t('chargeback')}
                  </Button>
                )
              }
            })}
          />
          <div className='mt-3' />
          <MetaBar
            createLink={`/payment-create?loan_id=${entityId}&product_code=${entity?.product_copy?.product_code}`}
            disableBorder
          />
        </>
      ) : (
        <small>{t('No data')}</small>
      )}

      {(error || rtkqError) && <DangerAlert error={error || rtkqError} />}
      <Modal isOpen={showModal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('Chargeback')}</ModalHeader>

        <ModalBody>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md='4'>
                <FormGroup>
                  <Label htmlFor='chargeback_date'>
                    {t('chargeback_date')}
                  </Label>
                  <Flatpickr
                    name='chargeback_date'
                    id='chargeback_date'
                    type='date'
                    className='form-control form-control-sm'
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: new Date(),
                      onChange: (selectedDates, dateStr) => {
                        formik.setFieldValue('chargeback_date', dateStr)
                      }
                    }}
                  />
                  {formik.touched.chargeback_date &&
                  formik.errors.chargeback_date ? (
                    <FormFeedback
                      style={{ display: 'block', marginBottom: '10px' }}
                      type='invalid'
                    >
                      <Alert color='warning'>
                        <strong> {formik.errors.chargeback_date} </strong>
                      </Alert>
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            {isError && (
              <Alert color='warning'>
                <strong>
                  {JSON.stringify(setChargebackError.data['detail'])}
                </strong>
              </Alert>
            )}

            {isSuccess && (
              <Alert color='success'>
                <strong> {t('Operation success')} </strong>
              </Alert>
            )}

            <Button type='submit' disabled={isLoading}>
              {t('Send')}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EntityContent
