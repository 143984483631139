import { UseQueryHookArgs } from 'src/services/api'
import model from 'src/model'

export type FieldsFilterState = {
  gender?: string
  is_accepted?: string | boolean
  [key: string]: any
}

export const paymentTypeByString = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  value: '1' | '2' | '3' | '4' | (string & {}) | null | undefined,
  defaultValue = '-'
) => {
  switch (value) {
    case '1':
      return '1 - Платёж по графику регулярный'
    case '2':
      return '2 - Частичное уменьшение срока'
    case '3':
      return '3 - Частичное уменьшение платежей'
    case '4':
      return '4 - Погасить полностью'
    default:
      return value ?? defaultValue
  }
}

export const applyFilter = <T>(
  values: FieldsFilterState,
  setFieldsFilter: (filter: UseQueryHookArgs<T>) => void
) => {
  const updatedFilter = { ...values }

  if (updatedFilter.gender) {
    updatedFilter.gender = model.getGenderCode(updatedFilter.gender)
  }

  if (updatedFilter.is_accepted) {
    updatedFilter.is_accepted = model.getPaymentStatus(
      updatedFilter.is_accepted
    )
  }

  setFieldsFilter(updatedFilter as UseQueryHookArgs<T>)
}
